// Home section 1
export const HOME_SECTION_1_HEADER = "VADÅ PAPPER?";
export const HOME_SECTION_1_SUB = "Vi jobbar inte så.";

// Home section 2
export const HOME_SECTION_2_HEADER = "Din digitala \n     redovisningsbyrå";
export const HOME_SECTION_2_PARAGRAPH =
  "Vi vet att bokföring kan vara tråkigt för många, ett så kallat nödvändigt ont. Vi älskar ordning och reda i kombination med siffror och tar mer än gärna hand om ditt kaos.";

//Home section 3
export const HOME_SECTION_3_HEADER = "VAD VI ERBJUDER";
export const HOME_SECTION_3_PARAGRAPH =
  "Vår ambition är att du ska sova gott om natten, medan vi tar hand om din bokföring. Du får sköta precis så mycket du vill – vissa av våra kunder sköter bokföringen själv och vill endast ha hjälp med bokslut & deklaration, medan andra vill ha hjälp med rubbet. Oavsett vem du är, så ska du alltid känna dig trygg med oss som din digitala redovisningsbyrå.";

export const HOME_SECTION_3_ITEM_1_HEADER = "DIGITALT";
export const HOME_SECTION_3_ITEM_1_TEXT =
  "Vi arbetar uteslutande digitalt, vilket innebär så lite papper som möjligt. Vi jobbar således inte med snigelpost, utan bara fota och skicka in dina kvitton!";

export const HOME_SECTION_3_ITEM_2_HEADER = "TRYGGT";
export const HOME_SECTION_3_ITEM_2_TEXT =
  "Vi finns här, även om vi är tysta och arbetar i bakgrunden. Du kan alltid höra av dig med frågor eller funderingar.";

export const HOME_SECTION_3_ITEM_3_HEADER = "FLEXIBELT";
export const HOME_SECTION_3_ITEM_3_TEXT =
  "Vi arbetar alltid med system som gör att geografisk plats spelar mindre roll. Du kan själv styra hur mycket du vill vara involverad i bokföringen.";

//Home section 4
export const HOME_SECTION_4_HEADER = "VÅRA TJÄNSTER";

export const HOME_SECTION_4_ITEM_1_TITLE = "LÖPANDE BOKFÖRING";
export const HOME_SECTION_4_ITEM_1_TEXT =
  "Vi sköter den löpande bokföringen under året, oavsett hur stort eller litet ditt bolag är. Bokföringen sköts i ett webbaserat bokföringssystem, vilket innebär att du har åtkomst till bokföringen, när du vill - hur du vill. Vi erbjuder full service inom bokföring och redovisning, men hjälper självklart till med utvalda delar också. Du bestämmer.";

export const HOME_SECTION_4_ITEM_2_TITLE = "BOKSLUT";
export const HOME_SECTION_4_ITEM_2_TEXT =
  "Vid verksamhetsårets slut behöver du antingen upprätta ett bokslut eller skicka in en årsredovisning. Verksamhetens storlek avgör vad som krävs för just er verksamhet – och vi vägleder dig genom processen och hjälper självklart till med detta.";

export const HOME_SECTION_4_ITEM_3_TITLE = "DEKLARATION";
export const HOME_SECTION_4_ITEM_3_TEXT =
  "Aldrig mer deklarationsångest - vi hjälper dig att deklarera rätt och i god tid. Förutom inkomstdeklarationen som görs vid årets slut kan vi också hjälpa till med löpande deklarationer i form av momsdeklaration och arbetsgivaravgifter.";

export const HOME_SECTION_4_ITEM_4_TITLE = "ÅRSREDOVISNING";
export const HOME_SECTION_4_ITEM_4_TEXT =
  "Senast 7 månader efter räkenskapsårets slut måste handlingen registreras hos Bolagsverket. Vi ser till att den kommer in i tid samt att alla nödvändiga delar finns med.";

//Home section 5
export const HOME_SECTION_5_HEADER = "INGEN SNIGELPOST";
export const HOME_SECTION_5_TEXT = "BARA FOTA OCH SKICKA IN DINA KVITTON";

//Home section 6
export const HOME_SECTION_6_HEADER = "HÖR AV DEJ";
export const HOME_SECTION_6_TEXT_1 =
  "Är du nyfiken på att veta mer eller har du \nnågra funderingar – tveka inte på att höra \nav dig.";
export const HOME_SECTION_6_TEXT_2 = "Don’t be a stranger.";
