// About section 1
export const ABOUT_SECTION_1_TITLE = "VÅR HISTORIA";
export const ABOUT_SECTION_1_TEXT =
  "Bolaget grundades 2017, när papper fortfarande var en grej. Vår tanke då var att erbjuda en helt digital lösning inom bokföring och redovisning. Vi avskyr papper, men älskar vårt jobb!";

export const ABOUT_SECTION_2_TITLE = "Vårt namn";
export const ABOUT_SECTION_2_TEXT =
  "Namnet är ett fyndigt försök att kombinera bokföring med IT, då vi är en digital redovisningsbyrå. När bolaget grundades var digitaliseringen ett faktum, men inte inom bokföringsvärlden. Balance IT uttalas ”Balance it” på engelska, men vi valde att stava IT med stora bokstäver för att visa på att IT är en viktig del inom vår organisation.";

export const ABOUT_SECTION_3_TEXT =
  "Vi vill vara din digitala redovisningsbyrå, där du kan sova gott om natten och slippa deklarationsångest!";

export const ABOUT_SECTION_4_WHO = "VILKA ÄR VI SOM SITTER BAKOM SKÄRMEN?";
export const ABOUT_SECTION_4_TERESE = "Terese";
export const ABOUT_SECTION_4_TERESE_INFO =
  "Vår IT-nörd som älskar ordning och reda - gärna i kombination med siffror.";
export const ABOUT_SECTION_4_CARINA = "Carina";
export const ABOUT_SECTION_4_CARINA_INFO =
  "Vår avdankade hockeyspelare som har stenkoll på allt i sin omgivning.";
export const ABOUT_SECTION_4_MARIA = "Maria";
export const ABOUT_SECTION_4_MARIA_INFO =
  "Vår stjärna med många års erfarenhet i branschen. Får hon välja så är ett glas mjölk aldrig fel.";
