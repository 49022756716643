import "./PersonellCard.css";

const PersonellCard = ({ Image, Name, Info }) => {
  return (
    <div className="personell-card-container">
      <img className="personell-card-photo" src={Image} />
      <span className="personell-card-name helvetica">{Name}</span>
      <span className="personell-card-text extra-light">{Info}</span>
    </div>
  );
};

export default PersonellCard;
