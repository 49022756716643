import "./LargeInfoBox.css";
import AArrow from "../../animated/arrow/AArrow";
import ScaleIcon from "../../icons/ScaleIcon";

const LargeInfoBox = ({
  Text = "PLACEHOLDER",
  Title = "PLACEHOLDER",
  Icon = null,
  OnClick = null,
  DynamicSize = false,
}) => {
  return (
    <div
      className={
        (OnClick == null ? "" : " pointer ") +
        (DynamicSize ? " dynamic-size " : "") +
        " large-info-box-container"
      }
      id={Title}
      onClick={OnClick}
    >
      <div className="lib-inner-contaier">
        <div className="lib-icon">
          <img src={Icon}></img>
        </div>
        <div className="lib-text-container">
          <h4 className="lib-title">{Title}</h4>
          <div className="lib-divider"></div>
          <p>{Text}</p>
        </div>
        <div className="arrow-container">
          <AArrow AnimateOnce={true} />
        </div>
      </div>
    </div>
  );
};

export default LargeInfoBox;
