// Services section 1
export const SERVICES_OUR_SERVICES_TITLE = "VÅRA TJÄNSTER";
export const SERVICES_OUR_SERVICES_ITEM_1_TITLE = "LÖPANDE BOKFÖRING";
export const SERVICES_OUR_SERVICES_ITEM_1_TEXT =
  "Vi sköter den löpande bokföringen under året, oavsett hur stort eller litet ditt bolag är. Bokföringen sköts i ett webbaserat bokföringssystem, vilket innebär att du har åtkomst till bokföringen, när du vill - hur du vill. Vi erbjuder full service inom bokföring och redovisning, men hjälper självklart till med utvalda delar också. Du bestämmer." +
  "\n" +
  "\nVissa av våra kunder sköter hela eller delar av den löpande bokföringen, medan vi bistår med rådgivning eller stöttar upp när det behövs. Vi anpassar oss med andra ord efter er verksamhets behov." +
  "\n" +
  "\nDen löpande bokföringen innefattar alla ekonomiska händelser, vilket exempelvis kan vara kund-och leverantörsfakturor, betalningar eller avstämningar. Vårt mål är att du som kund ska kunna släppa din bokföring och känna att den ligger i trygga händer hos oss.";

export const SERVICES_OUR_SERVICES_ITEM_2_TITLE = "BOKSLUT";
export const SERVICES_OUR_SERVICES_ITEM_2_TEXT =
  "Vid verksamhetsårets slut behöver du antingen upprätta ett bokslut eller skicka in en årsredovisning. Verksamhetens storlek avgör vad som krävs för just er verksamhet – och vi vägleder dig genom processen och hjälper självklart till med detta." +
  "\n" +
  "\nVi har en etablerad process för just bokslutsarbetet och för oss är det viktigt att kvalitetssäkra vårt arbete, vilket sker genom avstämningar. Några av våra kunder sköter den löpande bokföringen själva, och då blir det extra viktigt att enkelt kunna stämma av att bokföringen ser korrekt ut." +
  "\n" +
  "\nNär bokslutet är klart upprättar vi bokslutsbilagor och skickar över en sammanställning över året. Vissa kunder önskar en förklaring av Balans- och Resultatrapport i mer mänskliga termer, vilket vi också brukar göra.";

export const SERVICES_OUR_SERVICES_ITEM_3_TITLE = "DEKLARATION";
export const SERVICES_OUR_SERVICES_ITEM_3_TEXT =
  "Aldrig mer deklarationsångest - vi hjälper dig att deklarera rätt och i god tid. Förutom inkomstdeklarationen som görs vid årets slut kan vi också hjälpa till med löpande deklarationer i form av momsdeklaration och arbetsgivaravgifter. Vi agerar mer än gärna bollplank vid frågor kring deklarationen." +
  "\n" +
  "\nVad gäller de löpande deklarationerna så håller vi koll på alla viktiga datum och meddelar er i god tid när det är dags att betala in till Skatteverket. Inkomstdeklarationen behöver vi sällan påminna om, den har de flesta stenkoll på. Vissa tenderar däremot att ha mindre koll på hur den fylls i – men det löser vi åt dig. Vi fyller i bilagorna som avser ditt företag och säkerställer att allt ser rätt ut.";

export const SERVICES_OUR_SERVICES_ITEM_4_TITLE = "ÅRSREDOVISNING";
export const SERVICES_OUR_SERVICES_ITEM_4_TEXT =
  "Senast 7 månader efter räkenskapsårets slut måste handlingen registreras hos Bolagsverket. Vi ser till att den kommer in i tid samt att alla nödvändiga delar finns med." +
  "\n" +
  "\nÅrsredovisningen är tänkt att ge en rättvis bild över företagets ställning och resultat och ska spegla din verksamhets ekonomiska situation. Vi skickar in årsredovisningen digitalt och mailar över en kopia till er.";
// Services section 2
// Services section 3
export const SERVICES_TIMELINE_BOKSLUT_HEADER = "BOKSLUT";
export const SERVICES_TIMELINE_BOKSLUT_TEXT =
  "I början på året ber vi om kontoutdrag samt säkerställer att alla kvitton för året är inskickade. Vi stämmer av nödvändiga konton och hjälper till att sammanfatta verksamhetsåret.";
export const SERVICES_TIMELINE_LOPANDE_HEADER = "LÖPANDE BOKFÖRING";
export const SERVICES_TIMELINE_LOPANDE_TEXT =
  "Löpande bokföring innefattar alla transaktioner som sker under året. Oavsett om ditt bolag är stort eller litet, så ser vi till att sköta bokföringen så effektivt som möjligt för att minska onödiga timmar.";
export const SERVICES_TIMELINE_FIRA_HEADER = "FIRA";
export const SERVICES_TIMELINE_FIRA_TEXT =
  "Om året gått bra så kommer vi be er fira. Det är viktigt att stanna upp och fira framgångarna!";
export const SERVICES_TIMELINE_START_HEADER = "START";
export const SERVICES_TIMELINE_START_TEXT =
  "Har du precis startat bolag och behöver hjälp med de där tråkiga bitarna som ändå måste göras (även kallat ekonomi)? Eller har du skött bokföringen själv / byter från en annan byrå? Vi lovar att ta hand om dig.";
export const SERVICES_TIMELINE_DEKLARATION_HEADER = "DEKLARATION";
export const SERVICES_TIMELINE_DEKLARATION_TEXT =
  "Vi hjälper till med alla typer av deklaration, ser till att de fylls i rätt samt skickas in i tid.";
export const SERVICES_TIMELINE_ANNUAL_HEADER = "ÅRSREDOVISNING";
export const SERVICES_TIMELINE_ANNUAL_TEXT =
  "Behöver du upprätta en årsredovisning så hjälper vi självklart till med detta. Senast 7 månader efter räkenskapsårets slut måste handlingen registreras hos Bolagsverket. Vi ser till att den kommer in i tid samt att alla nödvändiga delar finns med.";
