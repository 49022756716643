const BigArrowIcon = ({ Color = "currentColor", Direction = "right" }) => {
  const rotations = {
    right: "rotate(180deg)",
    left: "rotate(0deg)",
  };
  const rotation = rotations[Direction];

  return (
    <svg
      fill={Color}
      style={{ transform: rotation, height: "inherit", width: "inherit" }}
      preserveAspectRatio="none"
      data-bbox="20.1 20 160 160"
      viewBox="20.1 20 160 160"
      height="200"
      width="200"
      xmlns="http://www.w3.org/2000/svg"
      data-type="shape"
      role="presentation"
      aria-hidden="true"
      aria-labelledby="svgcid--dw3ydl3itbck"
    >
      <title id="svgcid--dw3ydl3itbck"></title>
      <g>
        <path d="M100.1 20h80l-80 80 80 80h-80l-80-80 80-80z"></path>
      </g>
    </svg>
  );
};

export default BigArrowIcon;
