import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../button/Button";
import "./Navbar.css";
import { useLocation } from "react-router-dom";
import {
  mail_subject,
  mail_template,
  mail_to_adress,
  Routes,
} from "../../Constants";
import { IsScrollingUp } from "../../helpers/ScrollDirection";

const Navbar = () => {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState("/");
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [hideNav, setHideNav] = useState(false);

  let scrollingUp = false;
  scrollingUp = IsScrollingUp();

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location]);

  useEffect(() => {
    setHideNav(!scrollingUp);
  }, [scrollingUp]);

  return (
    <nav className={"navbar " + (hideNav && !navbarOpen ? "hidden" : "")}>
      <div className="navbar-container">
        <Link to="">
          <img
            style={{ height: "3rem", width: "100%" }}
            src={"./images/LogoWhiteText.png"}
            alt="logo"
          />
        </Link>
        <div className={"nav-links " + (navbarOpen ? "open" : "")}>
          <Link
            to={Routes.home}
            className={
              "home-link " + (currentLocation === Routes.home ? "active" : "")
            }
            onClick={() => setNavbarOpen(false)}
          >
            Hem
          </Link>
          <Link
            to={Routes.about}
            className={currentLocation === Routes.about ? "active" : ""}
            onClick={() => setNavbarOpen(false)}
          >
            Om oss
          </Link>
          <Link
            to={Routes.services}
            className={currentLocation === Routes.services ? "active" : ""}
            onClick={() => setNavbarOpen(false)}
          >
            Tjänster
          </Link>
          <Button
            Text="Kontakt"
            Classes="nav-button"
            OnClick={() => {
              window.location.href = `mailto:${mail_to_adress}?subject=${mail_subject}&body=${mail_template}`;
            }}
          />
        </div>
      </div>
      <div
        className={"hamburger-button " + (navbarOpen ? "open" : "")}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        <div className="hamburger-button-top"></div>
        <div className="hamburger-button-center"></div>
        <div className="hamburger-button-bottom"></div>
      </div>
    </nav>
  );
};

export default Navbar;
