import "./SmallInfoBox.css";

const SmallInfoBox = ({
  Text = "PLACEHOLDER",
  Title = "PLACEHOLDER",
  PreTitle = null,
  Icon = null,
}) => {
  return (
    <div className="small-info-box-container">
      {Icon == null ? (
        ""
      ) : (
        <>
          {Icon}
          <div className="line"></div>
        </>
      )}
      <div>
        {PreTitle ? <span className="pre-title">{PreTitle}</span> : ""}
        <span className="title"> {Title}</span>
      </div>
      <p className="text extra-light">{Text}</p>
    </div>
  );
};

export default SmallInfoBox;
