import bubblesVideo from "../../../assets/video/bubbles.mp4";
import {
  HOME_SECTION_1_HEADER,
  HOME_SECTION_1_SUB,
  HOME_SECTION_2_HEADER,
  HOME_SECTION_2_PARAGRAPH,
  HOME_SECTION_3_HEADER,
  HOME_SECTION_3_ITEM_1_HEADER,
  HOME_SECTION_3_ITEM_1_TEXT,
  HOME_SECTION_3_ITEM_2_HEADER,
  HOME_SECTION_3_ITEM_2_TEXT,
  HOME_SECTION_3_ITEM_3_HEADER,
  HOME_SECTION_3_ITEM_3_TEXT,
  HOME_SECTION_3_PARAGRAPH,
  HOME_SECTION_4_HEADER,
  HOME_SECTION_4_ITEM_1_TEXT,
  HOME_SECTION_4_ITEM_1_TITLE,
  HOME_SECTION_4_ITEM_2_TEXT,
  HOME_SECTION_4_ITEM_2_TITLE,
  HOME_SECTION_4_ITEM_3_TEXT,
  HOME_SECTION_4_ITEM_3_TITLE,
  HOME_SECTION_4_ITEM_4_TEXT,
  HOME_SECTION_4_ITEM_4_TITLE,
  HOME_SECTION_5_HEADER,
  HOME_SECTION_5_TEXT,
  HOME_SECTION_6_HEADER,
  HOME_SECTION_6_TEXT_1,
  HOME_SECTION_6_TEXT_2,
} from "../../../assets/texts/HomeTexts";
import Button from "../../button/Button";
import { useNavigate } from "react-router-dom";

import WaveSideBreak from "../../Sidebreaks/WaveSideBreak";
import "./Home.css";
import SmallInfoBox from "../../infoboxes/smallinfobox/SmallInfoBox";
import LargeInfoBox from "../../infoboxes/largeinfobox/LargeInfoBox";
import { useEffect } from "react";
import TypingText from "../../animated/text/TypingText";
import { mail_to } from "../../../Constants";
import AccountingIcon from "../../../assets/icons/accounting.png";
import AnnualReportIcon from "../../../assets/icons/annual_report.png";
import CalendarCheckIcon from "../../../assets/icons/calendar_check.png";
import DeclarationIcon from "../../../assets/icons/declaration.png";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const bubbleVideo = document.getElementById("bubbles-video");
    bubbleVideo.playbackRate = 0.6;
  }, []);

  const ScrollIntoView = (elementQuery) => {
    const element = document.querySelector(elementQuery);
    element.scrollIntoView();
  };

  return (
    <>
      <section className="home-section-1">
        <div>
          <h1 style={{ fontWeight: 600 }}>{HOME_SECTION_1_HEADER}</h1>
          <h3 className="poppins" style={{ fontWeight: 200 }}>
            {HOME_SECTION_1_SUB}
          </h3>
        </div>
        <WaveSideBreak Classes="color-dark-grey" Color="currentColor" />
      </section>
      <section className="home-section-2" style={{ height: "100vh" }}>
        <div>
          <h2>{HOME_SECTION_2_HEADER}</h2>
          <p>{HOME_SECTION_2_PARAGRAPH}</p>
        </div>
      </section>
      <section className="home-section-3">
        <WaveSideBreak
          Classes="color-dark-grey"
          Color="currentColor"
          Position="top"
        />

        <div className="centered max-60">
          <h2>{HOME_SECTION_3_HEADER}</h2>
          <p className="extra-light">{HOME_SECTION_3_PARAGRAPH}</p>
        </div>
        <div className="info-boxes">
          <SmallInfoBox
            PreTitle={"01 / "}
            Title={HOME_SECTION_3_ITEM_1_HEADER}
            Text={HOME_SECTION_3_ITEM_1_TEXT}
          />
          <SmallInfoBox
            PreTitle={"02 / "}
            Title={HOME_SECTION_3_ITEM_2_HEADER}
            Text={HOME_SECTION_3_ITEM_2_TEXT}
          />
          <SmallInfoBox
            PreTitle={"03 / "}
            Title={HOME_SECTION_3_ITEM_3_HEADER}
            Text={HOME_SECTION_3_ITEM_3_TEXT}
          />
        </div>
      </section>

      <section className="home-section-4">
        <video autoPlay loop muted id="bubbles-video">
          <source src={bubblesVideo} type="video/mp4" />
        </video>
        <div className="section-4-content std-padding">
          <h2>{HOME_SECTION_4_HEADER}</h2>
          <LargeInfoBox
            Title={HOME_SECTION_4_ITEM_1_TITLE}
            Text={HOME_SECTION_4_ITEM_1_TEXT}
            Icon={AccountingIcon}
            OnClick={() => {
              navigate("/services");
              ScrollIntoView(`#${HOME_SECTION_4_ITEM_1_TITLE}`);
            }}
          />
          <LargeInfoBox
            Title={HOME_SECTION_4_ITEM_2_TITLE}
            Text={HOME_SECTION_4_ITEM_2_TEXT}
            Icon={CalendarCheckIcon}
          />
          <LargeInfoBox
            Title={HOME_SECTION_4_ITEM_3_TITLE}
            Text={HOME_SECTION_4_ITEM_3_TEXT}
            Icon={DeclarationIcon}
          />
          <LargeInfoBox
            Title={HOME_SECTION_4_ITEM_4_TITLE}
            Text={HOME_SECTION_4_ITEM_4_TEXT}
            Icon={AnnualReportIcon}
          />
        </div>
      </section>
      <section className="home-section-5">
        <div className="typing-container">
          <TypingText
            Text={HOME_SECTION_5_HEADER}
            TextAfter={HOME_SECTION_5_TEXT}
            Classes="h2 writing-text helvetica"
          />
        </div>
      </section>
      <section className="home-section-6">
        <h1>{HOME_SECTION_6_HEADER}</h1>
        <p className="extra-light">{HOME_SECTION_6_TEXT_1}</p>
        <p>{HOME_SECTION_6_TEXT_2}</p>
        <Button
          Text="Swooosh"
          Classes="section-6-button"
          OnClick={() => {
            window.location = mail_to;
          }}
        />
      </section>
    </>
  );
};

export default Home;
