import { useRef, useState } from "react";
import * as Texts from "../../../assets/texts/ServicesTexts";
import { useOnScreen as isOnScreen } from "../../../helpers/useOnScreen";
import "./Timeline.css";

const Timeline = ({ AnimateOnce }) => {
  const part0 = useRef(null);
  const part1 = useRef(null);
  const part2 = useRef(null);
  const part3 = useRef(null);
  const part4 = useRef(null);
  const part5 = useRef(null);

  const [stopAnimation, setStopAnimation] = useState(false);

  const shouldAnimate = () => {
    if (isOnScreen && AnimateOnce && !stopAnimation) {
      setStopAnimation(true);
    }
    return isOnScreen && !stopAnimation;
  };

  return (
    <div className="timeline-container">
      <div
        ref={part0}
        className={(isOnScreen(part0) ? "" : "not-in-view") + " part-0"}
      >
        <h4
          className={(isOnScreen(part1) ? "" : " tc-hide ") + "timeline-title"}
        >
          <span className="timeline-line"></span>
          <span>{Texts.SERVICES_TIMELINE_START_HEADER}</span>
        </h4>
        <p style={isOnScreen(part0) ? {} : { display: "none" }}>
          {Texts.SERVICES_TIMELINE_START_TEXT}
        </p>
      </div>
      <div
        ref={part1}
        className={(isOnScreen(part1) ? "" : "not-in-view") + " part-1"}
      >
        <h4
          className={(isOnScreen(part1) ? "" : " tc-hide ") + "timeline-title"}
        >
          <span className="timeline-line"></span>
          <span>{Texts.SERVICES_TIMELINE_LOPANDE_HEADER}</span>
        </h4>
        <p style={isOnScreen(part1) ? {} : { display: "none" }}>
          {Texts.SERVICES_TIMELINE_LOPANDE_TEXT}
        </p>
      </div>
      <div
        ref={part2}
        className={(isOnScreen(part2) ? "" : "not-in-view") + " part-2"}
      >
        <h4
          className={(isOnScreen(part1) ? "" : " tc-hide ") + "timeline-title"}
        >
          <span className="timeline-line"></span>
          <span>{Texts.SERVICES_TIMELINE_DEKLARATION_HEADER}</span>
        </h4>
        <p style={isOnScreen(part2) ? {} : { display: "none" }}>
          {Texts.SERVICES_TIMELINE_DEKLARATION_TEXT}
        </p>
      </div>
      <div
        ref={part3}
        className={(isOnScreen(part3) ? "" : "not-in-view") + " part-3"}
      >
        <h4
          className={(isOnScreen(part1) ? "" : " tc-hide ") + "timeline-title"}
        >
          <span className="timeline-line"></span>
          <span>{Texts.SERVICES_TIMELINE_FIRA_HEADER}</span>
        </h4>
        <p style={isOnScreen(part3) ? {} : { display: "none" }}>
          {Texts.SERVICES_TIMELINE_FIRA_TEXT}
        </p>
      </div>
      <div
        ref={part4}
        className={(isOnScreen(part4) ? "" : "not-in-view") + " part-4"}
      >
        <h4
          className={(isOnScreen(part1) ? "" : " tc-hide ") + "timeline-title"}
        >
          <span className="timeline-line"></span>
          <span>{Texts.SERVICES_TIMELINE_BOKSLUT_HEADER}</span>
        </h4>
        <p style={isOnScreen(part4) ? {} : { display: "none" }}>
          {Texts.SERVICES_TIMELINE_BOKSLUT_TEXT}
        </p>
      </div>
      <div
        ref={part5}
        className={(isOnScreen(part5) ? "" : "not-in-view") + " part-5"}
      >
        <h4
          className={(isOnScreen(part1) ? "" : " tc-hide ") + "timeline-title"}
        >
          <span className="timeline-line"></span>
          <span>{Texts.SERVICES_TIMELINE_ANNUAL_HEADER}</span>
        </h4>
        <p style={isOnScreen(part5) ? {} : { display: "none" }}>
          {Texts.SERVICES_TIMELINE_ANNUAL_TEXT}
        </p>
      </div>
    </div>
  );
};

export default Timeline;
