export const Routes = {
  home: `${process.env.PUBLIC_URL}`,
  about: `${process.env.PUBLIC_URL}/about`,
  services: "/services",
};

export const mail_to_adress = "info@balanceit.se";
// export const mail_to_adress = "cedervall.andreas@gmail.com";
export const mail_subject = "";
export const mail_template = "";
// export const mail_template =
//   "Namn:  %0D%0A" +
//   "Telefonnummer:  %0D%0A %0D%0A" +
//   "Vi är riktigt less på papper och pärmar" +
//   " och skriver därmed till er... %0D%0A %0D%0A %0D%0A";
// %0D%0A is a new line

export const mail_to = `mailto:${mail_to_adress}?subject=${mail_subject}&body=${mail_template}`;
