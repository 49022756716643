import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import About from "./components/views/about/About";
import Home from "./components/views/home/Home";
import Services from "./components/views/services/Services";
import { Routes as paths } from "./Constants";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content-body">
          <Routes>
            <Route path={paths.home} element={<Home />} />
            <Route path={paths.about} element={<About />} />
            <Route path={paths.services} element={<Services />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
