import {
  SERVICES_OUR_SERVICES_ITEM_1_TEXT,
  SERVICES_OUR_SERVICES_ITEM_1_TITLE,
  SERVICES_OUR_SERVICES_ITEM_2_TEXT,
  SERVICES_OUR_SERVICES_ITEM_2_TITLE,
  SERVICES_OUR_SERVICES_ITEM_3_TEXT,
  SERVICES_OUR_SERVICES_ITEM_3_TITLE,
  SERVICES_OUR_SERVICES_ITEM_4_TEXT,
  SERVICES_OUR_SERVICES_ITEM_4_TITLE,
  SERVICES_OUR_SERVICES_TITLE,
} from "../../../assets/texts/ServicesTexts";
import LargeInfoBox from "../../infoboxes/largeinfobox/LargeInfoBox";
import "./Services.css";
import Timeline from "./Timeline";

const Services = () => {
  return (
    <>
      <section className="our-services">
        <h1>{SERVICES_OUR_SERVICES_TITLE}</h1>
        <LargeInfoBox
          DynamicSize={true}
          Title={SERVICES_OUR_SERVICES_ITEM_1_TITLE}
          Text={SERVICES_OUR_SERVICES_ITEM_1_TEXT}
        />
        <LargeInfoBox
          DynamicSize={true}
          Title={SERVICES_OUR_SERVICES_ITEM_2_TITLE}
          Text={SERVICES_OUR_SERVICES_ITEM_2_TEXT}
        />
        <LargeInfoBox
          DynamicSize={true}
          Title={SERVICES_OUR_SERVICES_ITEM_3_TITLE}
          Text={SERVICES_OUR_SERVICES_ITEM_3_TEXT}
        />
        <LargeInfoBox
          DynamicSize={true}
          Title={SERVICES_OUR_SERVICES_ITEM_4_TITLE}
          Text={SERVICES_OUR_SERVICES_ITEM_4_TEXT}
        />
      </section>
      {/* <section className="keeping-track">section2</section> */}
      <section className="timeline">
        <h1>RÄKENSKAPSÅRET</h1>
        <Timeline />
      </section>
    </>
  );
};

export default Services;
