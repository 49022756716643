import { FOOTER_TEXT } from "../../assets/texts/FooterTexts";
import "./Footer.css";

const Footer = () => {
  return (
    <section className="footer">
      <p>{FOOTER_TEXT}</p>
    </section>
  );
};

export default Footer;
