import { useEffect, useState } from "react";

export function IsScrollingUp() {
  const [y, setY] = useState(null);
  const [oldY, setOldY] = useState(null);
  const [isScrollingUp, setIsScrollingUp] = useState(true);

  function ScrollDepth() {
    setY(window.scrollY);
  }

  useEffect(() => {
    setY(window.pageYOffset);
    setOldY(window.pageYOffset);

    window.addEventListener("scroll", ScrollDepth);
    return () => {
      window.removeEventListener("scroll", ScrollDepth);
    };
  }, []);

  useEffect(() => {
    if (oldY == null) {
      setIsScrollingUp(true);
    } else {
      const tmpIsScrollingUp = y <= oldY;
      setIsScrollingUp(tmpIsScrollingUp);
    }
    setOldY(y);
  }, [y]);

  return isScrollingUp;
}
