import "./Button.css";

const buttonSizes = {
  small: "small-button",
  medium: "medium-button",
};

const Button = ({
  Text = "PLACEHOLDER",
  Size = "small",
  Classes = "",
  OnClick = () => {},
}) => {
  return (
    <button
      className={"button " + buttonSizes[Size] + " " + Classes}
      onClick={() => {
        OnClick();
      }}
    >
      {Text}
    </button>
  );
};

export default Button;
