import { useEffect, useRef, useState } from "react";
import { useOnScreen } from "../../../helpers/useOnScreen";
import "./TypingText.css";

const TypingText = ({
  Text = "Placeholder",
  TextAfter = "After",
  Classes = "",
}) => {
  const [blink, setBlink] = useState(true);
  const [textIndex, setTextIndex] = useState(0);
  const [showText, setShowText] = useState(false);

  const elementRef = useRef(null);
  const isOnScreen = useOnScreen(elementRef);

  useEffect(() => {
    const timeout1 = setTimeout(() => {
      if (isOnScreen && textIndex <= Text.length) {
        setTextIndex(textIndex + 1);
      } else if (textIndex > Text.length) {
        delay(500).then(() => {
          setShowText(true);
        });
      }
    }, 100);

    return () => clearTimeout(timeout1);
  }, [textIndex, isOnScreen]);

  // blinker
  useEffect(() => {
    const timeout2 = setTimeout(() => {
      setBlink((prev) => !prev);
    }, 500);

    return () => clearTimeout(timeout2);
  }, [blink]);

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  return (
    <span ref={elementRef} style={{ position: "relative" }} className={Classes}>
      <span>{`${Text.substring(0, textIndex)}`}</span>
      <span style={{ position: "absolute" }}>{blink ? " |" : " "}</span>
      <span className={(showText ? "tt-show-text" : "") + " text-after"}>
        {TextAfter}
      </span>
    </span>
  );
};

export default TypingText;
