import "./AArrow.css";
import { useRef, useState } from "react";
import BigArrowIcon from "../../icons/BigArrowIcon";
import { useOnScreen } from "../../../helpers/useOnScreen";

const AArrow = ({
  Direction = "right",
  Color = "currentColor",
  AnimateOnce,
}) => {
  const elementRef = useRef(null);
  const isOnScreen = useOnScreen(elementRef);

  const [stopAnimation, setStopAnimation] = useState(false);

  const shouldAnimate = () => {
    if (isOnScreen && AnimateOnce && !stopAnimation) {
      setStopAnimation(true);
    }

    return isOnScreen && !stopAnimation;
  };

  return (
    <div
      className={
        "big-arrow " +
        (shouldAnimate() ? "start " : "") +
        (stopAnimation ? "stop" : "")
      }
      ref={elementRef}
    >
      <BigArrowIcon Direction={Direction} Color={Color} />
    </div>
  );
};

export default AArrow;
