import {
  ABOUT_SECTION_1_TEXT,
  ABOUT_SECTION_1_TITLE,
  ABOUT_SECTION_2_TEXT,
  ABOUT_SECTION_2_TITLE,
  ABOUT_SECTION_3_TEXT,
  ABOUT_SECTION_4_CARINA,
  ABOUT_SECTION_4_CARINA_INFO,
  ABOUT_SECTION_4_MARIA,
  ABOUT_SECTION_4_MARIA_INFO,
  ABOUT_SECTION_4_TERESE,
  ABOUT_SECTION_4_TERESE_INFO,
  ABOUT_SECTION_4_WHO,
} from "../../../assets/texts/AboutTexts";
import "./About.css";
import whiteLogo from "../../../assets/images/LogoWhiteText.png";
import mariaImage from "../../../assets/images/Maria_Kvadrat.jpg";
import carinaImage from "../../../assets/images/Carina_Kvadrat.jpg";
import tereseImage from "../../../assets/images/Terese_Kvadrat.jpg";
import blackLogo from "../../../assets/images/LogoBlackText.png";
import PersonellCard from "./PeronellCard";

const About = () => {
  return (
    <>
      <section className="our-history">
        <h1>{ABOUT_SECTION_1_TITLE}</h1>
        <p>{ABOUT_SECTION_1_TEXT}</p>
      </section>
      <section className="our-name">
        <div className="our-name-logo">
          <img src={blackLogo} />
        </div>
        <div className="our-name-text">
          <h2>{ABOUT_SECTION_2_TITLE}</h2>
          <p className="extra-light">{ABOUT_SECTION_2_TEXT}</p>
        </div>
      </section>
      <section className="section-3-motto">
        <div>
          <p className="motto-text">{ABOUT_SECTION_3_TEXT}</p>
        </div>
      </section>
      <section className="section-4-who">
        <h2>{ABOUT_SECTION_4_WHO}</h2>
        <div className="who-card-container">
          <PersonellCard
            Image={tereseImage}
            Name={ABOUT_SECTION_4_TERESE}
            Info={ABOUT_SECTION_4_TERESE_INFO}
          />
          <PersonellCard
            Image={carinaImage}
            Name={ABOUT_SECTION_4_CARINA}
            Info={ABOUT_SECTION_4_CARINA_INFO}
          />
          <PersonellCard
            Image={mariaImage}
            Name={ABOUT_SECTION_4_MARIA}
            Info={ABOUT_SECTION_4_MARIA_INFO}
          />
        </div>
      </section>
    </>
  );
};

export default About;
